.page-id-27 {
  .robotic-features {
    padding: 70px 0 150px 0;
    @media only screen and (max-width: 768px) {
      padding: 35px 0 75px 0;
    }
  }
}

// Technology hero

.technology-hero {
  display: flex;
  align-items: flex-end;
  min-height: 380px;
  margin-top: 84px;
  padding: 80px 0;
  position: relative;
  @media only screen and (max-width: 768px) {
    min-height: 220px;
    margin-top: 80px;
    padding: 40px 0;
  }
  .text {
    text-align: center;
    .main-title {
      margin-bottom: 0;
      font-size: 72px;
      line-height: 115%;
      @media only screen and (max-width: 768px) {
        font-size: 44px;
        line-height: 115%;
      }
    }
  }
}

// Technology text

.technology-text {
  padding: 175px 0 70px 0;
  @media only screen and (max-width: 768px) {
    padding: 75px 0 35px 0;
  }
  .row {
    align-items: center;
  }
  .text {
    @media only screen and (max-width: 768px) {
      margin-bottom: 40px;
    }
    .secondary-title {
      text-align: center;
      margin-bottom: 45px;
      @media only screen and (max-width: 768px) {
        text-align: left;
        margin-bottom: 25px;
      }
    }
    p {
      width: 100%;
      max-width: 475px;
    }
  }
  .image {
    img {
      &:first-child {
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: 768px) {
          display: block;
        }
      }
    }
  }
}

// Technology details

.technology-details {
  position: relative;
  padding: 280px 0 245px 0;
  @media only screen and (max-width: 768px) {
    padding: 75px 0;
  }
  .bg {
    img {
      &:first-child {
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: 768px) {
          display: block;
        }
      }
    }
  }
  .image {
    padding: 95px 0 120px 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 47%;
    @media only screen and (max-width: 768px) {
      padding: 0 15px 0 0;
      margin-bottom: 48px;
      position: initial;
      max-width: 100%;
    }
    img {
      max-height: 100%;
    }
  }
  .text {
    max-width: 368px;
    .secondary-title {
      margin-bottom: 24px;
      font-size: 36px;
      line-height: 120%;
      color: white;
      @media only screen and (max-width: 768px) {
        margin-bottom: 16px;
      }
    }
    p {
      color: white;
      &:last-of-type {
        margin-bottom: 56px;
        @media only screen and (max-width: 768px) {
          margin-bottom: 36px;
        }
      }
    }
    .btn-arrow {
      background-color: $green;
      color: $green-dark;
    }
  }
}