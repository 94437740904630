// News hero

.news-hero {
  background-color: #F5F6F7;
  padding: 80px 0;
  margin-top: 84px;
  @media only screen and (max-width: 768px) {
    padding: 60px 0;
  }
  .row {
    align-items: center;
  }
  .text {
    max-width: 425px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 40px;
    }
    .main-title {
      margin-bottom: 26px;
      color: $dark;
      @media only screen and (max-width: 768px) {
        margin-bottom: 16px;
      }
      @media only screen and (max-width: 768px) {
        margin-bottom: 16px;
        font-size: 44px;
      }
    }
    p {
      font-size: 18px;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
  .info {
    max-width: 660px;
    border-radius: 24px;
    padding: 48px;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      padding: 36px 28px 68px 28px;
    }
    .bg {
      img {
        object-fit: contain;
        border-radius: 24px;
        @media only screen and (max-width: 1200px) {
          object-fit: cover;
        }
        &:first-child {
          @media only screen and (max-width: 768px) {
            display: none;
          }
        }
        &:nth-of-type(2) {
          display: none;
          @media only screen and (max-width: 768px) {
            display: block;
          }
        }
      }
    }
    .logos {
      display: flex;
      margin-bottom: 33px;
      @media only screen and (max-width: 768px) {
        order: 4;
        flex-direction: column;
        margin-bottom: 0;
      }
      img {
        width: auto;
        height: 77px;
        margin-right: 30px;
        @media only screen and (max-width: 768px) {
          width: 120px;
          height: auto;
          margin: 0 0 24px 0;
        }
        &:last-of-type {
          @media only screen and (max-width: 768px) {
            margin-bottom: 0;
          }
        }
      }
    }
    .secondary-title {
      width: 100%;
      max-width: 420px;
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 36px;
      line-height: 120%;
      color: white;
      @media only screen and (max-width: 768px) {
        order: 1;
        font-size: 34px;
        max-width: 265px;
      }
    }
    p {
      width: 100%;
      max-width: 420px;
      font-size: 15px;
      color: white;
      @media only screen and (max-width: 768px) {
        order: 2;
        max-width: 265px;
      }
      &:last-of-type {
        margin-bottom: 52px;
        @media only screen and (max-width: 768px) {
          margin-bottom: 16px;
        }
      }
    }
    .more {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $green;
      @media only screen and (max-width: 768px) {
        order: 3;
        margin-bottom: 73px;
      }
    }
  }
}

// News list

.news-list {
  padding: 125px 0 95px 0;
  @media only screen and (max-width: 768px) {
    padding: 65px 0 35px 0;
  }
  div[class*="col-"]{
    margin-bottom: 30px;
  }
  //.list-item {
  //  display: none;
  //}
  .item {
    border-radius: 24px;
    background-color: #F5F6F6;
    .image {
      display: inline-flex;
      height: 236px;
      width: 100%;
      @media only screen and (max-width: 768px) {
        height: 205px;
      }
      img {
        border-radius: 24px 24px 0 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        @media only screen and (max-width: 768px) {
          border-radius: 20px 20px 0 0;
        }
      }
    }
    .info {
      padding: 40px 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media only screen and (max-width: 768px) {
        padding: 28px 0 0 0;
      }
      .top {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 24px;
        margin-bottom: 24px;
        @media only screen and (max-width: 1200px) {
          padding: 0 20px;
        }
        @media only screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
        .category {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 28px;
          padding: 0 12px;
          margin: 0 44px 20px 0;
          background-color: $green;
          border-radius: 24px;
          position: relative;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          color: #FFFFFF;
          svg {
            width: 32px;
            position: absolute;
            right: -32px;
          }
        }
        .place {
          margin-bottom: 20px;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 120%;
          color: $grey;

        }
        .title {
          width: 100%;
          display: inline-flex;
          flex-wrap: wrap;
          h3 {
            min-height: 84px;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 120%;
            color: $dark;
            @media only screen and (max-width: 768px) {
              min-height: auto;
              font-size: 20px;
            }
          }
        }
      }
      .bottom {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding: 0 0 0 24px;
        @media only screen and (max-width: 1200px) {
          padding: 0 0 0 20px;
        }
        .date {
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 120%;
          color: $grey;
        }
        .more {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          border-radius: 24px 0 0 0;
          padding: 21px 24px 35px 24px;
          white-space: nowrap;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $green;
        }
      }
    }
  }
  .show-more {
    margin-top: 30px;
    text-align: center;
    .btn-arrow {
      color: $green-dark;
    }
  }
}