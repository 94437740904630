.page-id-39 {
  overflow: initial;
  .touch-form .text .secondary-title {
    max-width: 100%;
  }
  .touch-form {
    .form {
      height: 100%;
      position: relative;
      .nf-form-cont {
        position: sticky;
        top: 95px;
        @media only screen and (max-width: 768px) {
          position: initial;
        }
      }
      .text {
        padding-top: 290px;
        @media only screen and (max-width: 768px) {
          padding-top: 0;
        }
        h3 {
          color: $grey;
        }
        .margin-top {
          margin-top: 111px;
          display: block;
          @media only screen and (max-width: 768px) {
            margin-top: 0;
          }
        }
      }
      .btn-arrow {
        min-width: 273px;
        height: 44px;
        margin-bottom: 54px;
        padding: 0 26px;
        justify-content: flex-start;
        background-color: $green;
        color: white;
        svg {
          width: 44px;
          height: 44px;
        }
      }
      form {
        .nf-form-content {
          nf-fields-wrap {
            nf-field:nth-last-child(3) {
              width: 48%;
              @media only screen and (max-width: 768px) {
                width: 100%;
              }
              .nf-field-label {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

// Touch hero

.touch-hero {
  display: flex;
  align-items: flex-end;
  min-height: 380px;
  margin-top: 84px;
  padding: 80px 0;
  position: relative;
  @media only screen and (max-width: 768px) {
    min-height: 220px;
    margin-top: 80px;
    padding: 40px 0;
  }
  .text {
    text-align: center;
  }
  .bg {
    img {
      &:first-of-type {
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: 768px) {
          display: block;
        }
      }
    }
  }
}

// Touch form

.touch-form {
  padding: 170px 0 140px 0;
  @media only screen and (max-width: 768px) {
    padding: 75px 0;
  }
  .text {
    @media only screen and (max-width: 992px) {
      margin-bottom: 50px;
    }
    .secondary-title {
      max-width: 500px;
    }
    p {
      max-width: 430px;
    }
    ul {
      padding: 0 0 0 20px;
      list-style: disc;
      li {
        margin-bottom: 10px;
        font-family: 'Outfit', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 160%;
        color: $grey;
      }
    }
    table {
      border: 1px solid $grey;
      margin-bottom: 16px;
      tr {
        border-bottom: 1px solid $grey;
        &:last-of-type {
          border-bottom: 0;
        }
        td {
          padding: 10px;
          border-right: 1px solid $grey;
          font-family: 'Outfit', sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 160%;
          color: $grey;
          &:last-of-type {
            border-right: 0;
          }
        }
      }
    }
  }
  .form {
    //.wpforms-container {
    //  margin-top: 0;
    //}
    //.wpforms-form {
    //  .wpforms-field {
    //    input {
    //      height: 48px;
    //      background-color: transparent;
    //      border: 0;
    //      padding: 0;
    //      border-bottom: 1px solid rgba(66, 68, 72, 0.2);
    //      border-radius: 0;
    //      transition: .3s;
    //      font-style: normal;
    //      font-weight: 300;
    //      font-size: 18px;
    //      line-height: 150%;
    //      color: $grey;
    //      &::placeholder {
    //        font-style: normal;
    //        font-weight: 300;
    //        font-size: 18px;
    //        line-height: 150%;
    //        color: rgba(66, 68, 72, 0.6);
    //      }
    //      &:focus {
    //        background-color: #F5F6F6;
    //        border-color: $green;
    //        padding: 0 16px;
    //      }
    //    }
    //    &.wpforms-field-checkbox  {
    //      display: flex;
    //      ul li {
    //        input {
    //          height: auto;
    //        }
    //      }
    //    }
    //    .wpforms-field-description {
    //      margin-top: 0;
    //      font-style: normal;
    //      font-weight: 300;
    //      font-size: 14px;
    //      line-height: 18px;
    //      color: rgba(24, 26, 32, 0.4);
    //      a {
    //        color: rgba(24, 26, 32, 0.4);
    //        text-decoration: underline;
    //      }
    //    }
    //  }
    //  .wpforms-submit-container {
    //    width: 38%;
    //    position: relative;
    //    @media only screen and (max-width: 768px) {
    //      max-width: 210px;
    //    }
    //    &:hover {
    //      transition: .3s;
    //      &:before {
    //        right: -10px;
    //      }
    //    }
    //    &:before {
    //      position: absolute;
    //      content: '';
    //      right: 0;
    //      height: 44px;
    //      width: 44px;
    //      background-image: url("../img/icon-submit.svg");
    //      background-repeat: no-repeat;
    //      background-size: cover;
    //      transition: .3s;
    //      z-index: 1;
    //    }
    //    .wpforms-submit {
    //      display: inline-flex;
    //      justify-content: flex-start;
    //      align-items: center;
    //      width: 100%;
    //      height: 44px;
    //      border: 1px solid $green;
    //      background-color: white;
    //      border-radius: 48px;
    //      padding: 0 44px 0 26px;
    //      outline: none;
    //      position: relative;
    //      transition: .3s;
    //      font-style: normal;
    //      font-weight: 600;
    //      font-size: 14px;
    //      line-height: 18px;
    //      text-transform: capitalize;
    //      color: $green-dark;
    //      &:hover {
    //        transition: .3s;
    //        text-decoration: none;
    //      }
    //    }
    //  }
    //}
    form {
      .nf-before-form-content {
        display: none;
      }
      .nf-form-content {
        padding: 0;
        nf-fields-wrap {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          nf-field {
            width: 48%;
            margin-bottom: 34px;
            @media only screen and (max-width: 768px) {
              width: 100%;
            }
            &:nth-last-child(3) {
              width: 100%;
              .nf-field-label {
                display: block;
              }
            }
            &:nth-last-child(2) {
              width: 55%;
              @media only screen and (max-width: 768px) {
                width: 100%;
              }
              .checkbox-container.label-right label {
                cursor: pointer;
                &:before {
                  left: -27px;
                  top: -3px;
                  font-size: 18px;
                  color: $green;
                  @media only screen and (max-width: 768px) {
                    top: -5px;
                  }
                }
                &:after {
                  top: 0;
                  width: 20px;
                  height: 20px;
                  border: 1px solid #D1D3D3;
                  border-radius: 4px;
                  background-color: transparent;
                  cursor: pointer;
                  @media only screen and (max-width: 768px) {
                    top: 0;
                  }
                }
              }
              .nf-field-label {
                display: block;
                width: auto;
              }
              .nf-field-element {
                input {
                  height: auto;
                }
              }
              .nf-field-description {
                margin-right: 0;
                p {
                  font-style: normal;
                  font-weight: 300;
                  font-size: 14px;
                  line-height: 18px;
                  color: rgba(24, 26, 32, 0.4);
                  span {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 18px;
                    color: rgba(24, 26, 32, 0.4);
                  }
                  a {
                    color: rgba(24, 26, 32, 0.4);
                    text-decoration: underline;
                  }
                }
              }
            }
            &:last-child {
              width: 38%;
              @media only screen and (max-width: 768px) {
                width: 100%;
              }
              .nf-field-element {
                position: relative;
                @media only screen and (max-width: 768px) {
                  max-width: 210px;
                }
                &:hover {
                  transition: .3s;
                  &:before {
                    right: -10px;
                  }
                }
                &:before {
                  position: absolute;
                  content: '';
                  right: 0;
                  height: 44px;
                  width: 44px;
                  background-image: url("../img/icon-submit.svg");
                  background-repeat: no-repeat;
                  background-size: cover;
                  transition: .3s;
                }
              }
              input[type="button"] {
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 44px;
                border: 1px solid $green;
                border-radius: 48px;
                padding: 0 44px 0 26px;
                outline: none;
                position: relative;
                transition: .3s;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                text-transform: capitalize;
                color: $green-dark;
                &:hover {
                  transition: .3s;
                  text-decoration: none;
                }
              }
            }
            .nf-field-label {
              display: none;
            }
            .ninja-forms-req-symbol {
              display: none;
            }
            label {
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 150%;
              color: rgba(66, 68, 72, 0.6);
            }
            input, select {
              height: 48px;
              background-color: transparent;
              border: 0;
              padding: 0;
              border-bottom: 1px solid rgba(66, 68, 72, 0.2);
              outline: none;
              box-shadow: none;
              appearance: none;
              border-radius: 0;
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 150%;
              color: $grey;
              &::placeholder {
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 150%;
                color: rgba(66, 68, 72, 0.6);
              }
              &:focus {
                background-color: #F5F6F6;
                border-color: $green;
                padding: 0 16px;
              }
            }
            textarea {
              background-color: transparent;
              padding: 16px;
              min-height: 211px;
              border: 1px solid #D1D3D3;
              border-radius: 12px;
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 150%;
              color: $grey;
            }
            .nf-field-container {
              margin-bottom: 0;
            }
            .nf-error-msg {
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 120%;
              color: #C61C42;
            }
            .nf-error {
              input {
                border-color: #C61C42;
                &::placeholder {
                  color: #C61C42;
                }
              }
            }
            .nf-field-element {
              &:after {
                display: none;
              }
            }
          }
        }
      }
      .nf-after-form-content {
        .nf-error-msg {
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 120%;
          color: #C61C42;
        }
      }
    }
  }
  .thank-you {
    display: none;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 370px;
    h3 {
      margin-bottom: 23px;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 120%;
      color: $dark;
      @media only screen and (max-width: 768px) {
        margin-bottom: 16px;
        font-size: 26px;
      }
    }
    .image {
      margin: 60px 0 80px 0;
      @media only screen and (max-width: 768px) {
        margin: 40px 0 50px 0;
      }
      img {
        height: 175px;
        @media only screen and (max-width: 768px) {
          height: 120px;
        }
      }
    }
    p {
      color: $grey;
    }
    .learn {
      p {
        font-size: 18px;
      }
      a {
        color: $green;
      }
    }
  }
}