// Error

.error {
  padding: 230px 0 185px 0;
  margin-top: 84px;
  position: relative;
  @media only screen and (max-width: 768px) {
    padding: 210px 0 75px 0;
    margin-top: 80px;
  }
  .vector-left {
    left: 0;
    top: 0;
    position: absolute;
    width: 430px;
    @media only screen and (max-width: 1200px) {
      width: 350px;
    }
    @media only screen and (max-width: 992px) {
      width: 250px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .vector-right {
    right: 0;
    bottom: 115px;
    position: absolute;
    width: 430px;
    @media only screen and (max-width: 1200px) {
      width: 350px;
    }
    @media only screen and (max-width: 992px) {
      width: 250px;
      bottom: 50px;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .text {
    text-align: center;
    .main-title {
      margin-bottom: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 230px;
      line-height: 110%;
      letter-spacing: -0.03em;
      color: $dark;
      @media only screen and (max-width: 768px) {
        font-size: 134px;
      }
      span {
        display: inline-flex;
        background-image: url("../img/icon-error.svg");
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 20px;
        width: 126px;
        height: 162px;
        @media only screen and (max-width: 768px) {
          width: 74px;
          height: 95px;
        }
      }
    }
    p {
      &:last-child {
        margin-bottom: 45px;
        @media only screen and (max-width: 768px) {
          margin-bottom: 35px;
        }
      }
    }
    a {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $green;
    }
  }
}