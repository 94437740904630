// Robotic worker hero

.robotic-hero {
  background-color: #F5F6F7;
  margin-top: 84px;
  padding: 105px 0 220px 0;
  position: relative;
  @media only screen and (max-width: 992px) {
    padding: 40px 0 70px 0;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 80px;
  }
  .bg {
    right: 0;
    left: initial;
    width: 55%;
    z-index: 1;
    @media only screen and (max-width: 1500px) {
      right: -5%;
      width: 61%;
    }
    @media only screen and (max-width: 1300px) {
      width: 67%;
    }
    @media only screen and (max-width: 1224px) {
      right: -15%;
      width: 73%;
    }
    @media only screen and (max-width: 1124px) {
      right: -25%;
      width: 80%;
    }
    @media only screen and (max-width: 1024px) {
      right: -30%;
      width: 82%;
    }
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  .text {
    .main-title {
      margin-bottom: 32px;
      color: $dark;
      @media only screen and (max-width: 992px) {
        margin-bottom: 24px;
      }
      span {
        display: inline-flex;
        background-image: url("../img/icon-robotics.svg");
        background-size: contain;
        background-repeat: no-repeat;
        height: 42px;
        width: 33px;
        @media only screen and (max-width: 768px) {
          height: 24px;
          width: 19px;
        }
      }
    }
    p {
      max-width: 430px;
      font-size: 18px;
      line-height: 27px;
      &:last-of-type {
        margin-bottom: 64px;
        @media only screen and (max-width: 768px) {
          margin-bottom: 40px;
        }
      }
    }
  }
  .image {
    width: 555px;
    text-align: right;
    position: relative;
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      width: 270px;
      margin-left: 40px;
    }
    img {
      @media only screen and (max-width: 768px) {
        order: 2;
      }
    }
    .text-item {
      position: absolute;
      @media only screen and (max-width: 768px) {
        position: initial;
      }
      &:first-of-type {
        left: 0;
        top: 25px;
        @media only screen and (max-width: 768px) {
          margin: 0 0 19px 10px;
          order: 1;
        }
        p {
          max-width: 256px;
        }
        .vector {
          width: 223px;
          top: 35px;
          left: 76px;
          @media only screen and (max-width: 768px) {
            width: 38px;
            height: 252px;
            left: -35px;
          }
          img {
            height: auto;
            width: auto;
            min-width: auto;
          }
        }
      }
      &:nth-of-type(2) {
        display: flex;
        right: -105px;
        bottom: -130px;
        @media only screen and (max-width: 768px) {
          order: 3;
          margin: 45px 0 0 -30px;
        }
        p {
          max-width: 288px;
        }
        .icon {
          margin-right: 16px;
        }
        .vector {
          height: 155px;
          width: 53px;
          top: -150px;
          left: 38px;
          @media only screen and (max-width: 768px) {
            width: 154px;
            height: 78px;
            top: -166px;
            left: 35px;
          }
          img {
            height: auto;
            width: auto;
            min-width: auto;
          }
        }
      }
      p {
        text-align: left;
        font-size: 18px;
      }
      .icon {
        text-align: left;
        margin-bottom: 16px;
        position: relative;
        img {
          width: 72px;
          min-width: 72px;
          @media only screen and (max-width: 768px) {
            width: 64px;
            min-width: 64px;
          }
        }
        .vector {
          position: absolute;
          img {
            @media only screen and (max-width: 768px) {
              min-width: auto;
            }
            &:first-of-type {
              @media only screen and (max-width: 768px) {
                display: none;
              }
            }
            &:nth-of-type(2) {
              display: none;
              @media only screen and (max-width: 768px) {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

// Robotic explore

.robotic-explore {
  padding: 125px 0 65px 0;
  position: relative;
  @media only screen and (max-width: 768px) {
    padding: 72px 0;
  }
  .bg {
    img {
      &:first-of-type {
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: 768px) {
          display: block;
        }
      }
    }
  }
  .vector {
    position: absolute;
    left: 35%;
    bottom: 167px;
  }
  .text {
    padding-top: 50px;
    @media only screen and (max-width: 768px) {
      padding-top: 0;
      margin-bottom: 48px;
    }
    .main-title {
      margin-bottom: 26px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 24px;
      }
    }
    p {
      color: white;
      span {
        display: inline-flex;
        background-image: url("../img/icon-plus.svg");
        background-size: contain;
        background-repeat: no-repeat;
        height: 14px;
        width: 14px;
        margin: 0 3px;
      }
    }
  }
  .image {
    position: relative;
    max-width: 456px;
    @media only screen and (max-width: 768px) {
      max-width: 320px;
      margin: 0 auto;
    }
    .popups {
      @media only screen and (max-width: 768px) {
        position: relative;
      }
      .item {
        width: 100%;
        max-width: 436px;
        position: absolute;
        z-index: 1;
        @media only screen and (max-width: 768px) {
          position: initial;
          height: 0;
        }
        &.active {
          backdrop-filter: blur(5px);
          z-index: 2;
          @media only screen and (max-width: 768px) {
            height: auto;
          }
          .text {
            transition: .3s;
            opacity: 1;
            z-index: 1;
            visibility: visible;
            background-color: rgba(24, 26, 32, 0.5);
            @media only screen and (max-width: 768px) {
              display: block;
            }
          }
        }
        .text {
          transition: .3s;
          opacity: 0;
          z-index: -1;
          visibility: hidden;
          background-image: url("../img/bg-popup.svg");
          background-size: 100%;
          background-repeat: no-repeat;
          padding: 60px 40px 10px 40px;
          @media only screen and (max-width: 768px) {
            display: none;
            padding: 28px;
            background-image: url("../img/bg-popup-mobile.svg");
            background-size: 100%;
            margin-bottom: 0;
          }
          h3 {
            margin-bottom: 20px;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 120%;
            color: #FFFFFF;
            @media only screen and (max-width: 768px) {
              margin-bottom: 16px;
              font-size: 20px;
            }
          }
          p {
            font-size: 18px;
            color: white;
          }
        }
        .action {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
          z-index: 1;
          &.show-desctop {
            @media only screen and (max-width: 768px) {
              display: none;
            }
          }
          &.show-mobile {
            display: none;
            @media only screen and (max-width: 768px) {
              display: block;
            }
          }
          svg {
            @media only screen and (max-width: 768px) {
              width: 24px;
              height: 24px;
            }
            &:nth-of-type(2),
            &:nth-of-type(3) {
              display: none;
            }
          }
          &.active {
            svg {
              &:first-of-type {
                display: none;
              }
              &:nth-of-type(2) {
                display: none;
                @media only screen and (max-width: 768px) {
                  display: block;
                }
              }
              &:nth-of-type(3) {
                display: block;
              }
            }
          }
        }
        &:first-of-type {
          top: -20px;
          right: 191px;
          .action {
            @media only screen and (max-width: 768px) {
              svg {
                &:first-of-type{
                  position: absolute;
                  right: 140px;
                  top: -377px;
                }
                &:nth-of-type(2) {
                  position: absolute;
                  right: 140px;
                  top: -377px;
                }
              }
            }
          }
        }
        &:nth-of-type(2) {
          top: 130px;
          right: 24px;
          .action {
            @media only screen and (max-width: 768px) {
              svg {
                &:first-of-type{
                  position: absolute;
                  right: 30px;
                  top: -279px;
                }
                &:nth-of-type(2) {
                  position: absolute;
                  right: 30px;
                  top: -279px;
                }
              }
            }
          }
        }
        &:nth-of-type(3) {
          top: 195px;
          right: 13px;
          .action {
            @media only screen and (max-width: 768px) {
              svg {
                &:first-of-type{
                  position: absolute;
                  right: 8px;
                  top: -227px;
                }
                &:nth-of-type(2) {
                  position: absolute;
                  right: 8px;
                  top: -227px;
                }
              }
            }
          }
        }
        &:nth-of-type(4) {
          top: 240px;
          right: 75px;
          .action {
            @media only screen and (max-width: 768px) {
              svg {
                &:first-of-type{
                  position: absolute;
                  right: 63px;
                  top: -191px;
                }
                &:nth-of-type(2) {
                  position: absolute;
                  right: 63px;
                  top: -191px;
                }
              }
            }
          }
        }
        &:nth-of-type(5) {
          top: 285px;
          right: 387px;
          .action {
            @media only screen and (max-width: 768px) {
              svg {
                &:first-of-type{
                  position: absolute;
                  right: 274px;
                  top: -176px;
                }
                &:nth-of-type(2) {
                  position: absolute;
                  right: 274px;
                  top: -176px;
                }
              }
            }
          }
        }
        &:nth-of-type(6) {
          top: 420px;
          right: 15px;
          .action {
            @media only screen and (max-width: 768px) {
              svg {
                &:first-of-type{
                  position: absolute;
                  right: 10px;
                  top: -75px;
                }
                &:nth-of-type(2) {
                  position: absolute;
                  right: 10px;
                  top: -75px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Robotic features

.robotic-features {
  padding: 190px 0;
  @media only screen and (max-width: 768px) {
    padding: 72px 0;
  }
  .wrapper {
    margin-bottom: 135px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 60px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &:nth-of-type(even) {
      div[class*="col-"] {
        &:first-of-type {
          order: 2;
          @media only screen and (max-width: 768px) {
            order: 1;
          }
        }
        &:nth-of-type(2) {
          order: 1;
          @media only screen and (max-width: 768px) {
            order: 2;
          }
        }
      }
      .video {
        padding: 0 0 0 31px;
        @media only screen and (max-width: 768px) {
          padding: 0;
        }
        .shadow {
          left: initial;
          right: 0;
          @media only screen and (max-width: 768px) {
            right: initial;
          }
        }
        &:before {
          right: initial;
          left: 15px;
          @media only screen and (max-width: 768px) {
            left: 33px;
          }
        }
        &:after {
          right: initial;
          left: 6px;
          @media only screen and (max-width: 768px) {
            left: 28px;
          }
        }
      }
      .text {
        h3 {
          &:before {
            left: calc(100% + 8px);
          }
        }
      }
    }
  }
  .video {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    position: relative;
    background-color: white;
    padding-right: 31px;
    z-index: 1;
    @media only screen and (max-width: 768px) {
      max-width: 100%;
      margin: 0 auto;
      padding-right: 0;
    }
    .shadow {
      position: absolute;
      max-height: 100%;
      max-width: 100%;
      height: 470px;
      width: 470px;
      left: 0;
      top: 0;
      background-color: rgba(12, 14, 17, 0.15);
      border-radius: 24px;
      @media only screen and (max-width: 768px) {
        height: 100%;
        left: initial;
      }
    }
    svg, video, img, iframe {
      border-radius: 24px;
    }
    img {
      max-height: 100%;
      max-width: 100%;
      height: 470px;
      width: 470px;
      object-fit: cover;
      @media only screen and (max-width: 768px) {
        height: auto;
      }
    }
    &:before {
      position: absolute;
      right: 15px;
      top: calc(50% - 15px);
      content: '';
      width: 30px;
      height: 30px;
      background-color: $green;
      border-radius: 50%;
      @media only screen and (max-width: 768px) {
        width: 18px;
        height: 18px;
        left: 33px;
        bottom: -9px;
        top: initial;
        right: initial;
      }
    }
    &:after {
      position: absolute;
      right: 6px;
      top: calc(50% - 22.5px);
      content: '';
      width: 45px;
      height: 45px;
      border: 1px solid $green;
      border-radius: 50%;
      z-index: -1;
      @media only screen and (max-width: 768px) {
        width: 28px;
        height: 28px;
        left: 28px;
        bottom: -14px;
        top: initial;
        right: initial;
      }
    }
    .open-modal {
      cursor: pointer;
      position: absolute;
      transition: .3s;
      svg {
        @media only screen and (max-width: 768px) {
          width: 90px;
          height: 90px;
        }
      }
      &:hover {
        transition: .3s;
        transform: scale(1.1);
      }
    }
  }
  .text {
    padding-top: 150px;
    @media only screen and (max-width: 768px) {
      padding-top: 66px;
    }
    .secondary-title {
      padding-left: 24px;
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 36px;
      line-height: 120%;
      @media only screen and (max-width: 768px) {
        padding-left: 32px;
        margin-bottom: 12px;
        font-size: 34px;
        line-height: 120%;
        position: relative;
        &:before {
          position: absolute;
          content: '';
          width: 2px;
          height: 32px;
          left: 41px;
          top: -40px;
          background-color: #E8E9E9;
        }
      }
    }
    h3 {
      display: inline-flex;
      align-items: center;
      padding: 11px 24px;
      background-color: #F5F6F6;
      border-radius: 24px;
      margin-bottom: 40px;
      position: relative;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      color: $dark;
      @media only screen and (max-width: 768px) {
        margin-bottom: 20px;
        border-radius: 36px;
        font-size: 18px;
        line-height: 150%;
      }
      &:before {
        position: absolute;
        content: '';
        width: 75%;
        height: 2px;
        right: calc(100% + 8px);
        background-color: #E8E9E9;
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    p {
      padding-left: 24px;
      @media only screen and (max-width: 768px) {
        padding-left: 32px;
      }
    }
  }
}