// Footer

.footer {
  background-color: #F5F6F6;
  padding: 100px 0 45px 0;
  @media only screen and (max-width: 768px) {
    padding: 70px 0 25px 0;
  }
  .flex-top {
    @media only screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      margin-bottom: 32px;
      border-bottom: 1px solid rgba(66, 68, 72, 0.2);
    }
  }
  .logo {
    margin-bottom: 32px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
    }
    img {
      height: 45px;
      @media only screen and (max-width: 768px) {
        min-width: 65px;
        margin: 0 20px 0 0;
      }
    }
  }
  .text {
    max-width: 395px;
    margin-bottom: 80px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
    }
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      color: $dark;
      @media only screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
  .avocadostories {
    &.show-desctop {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    &.show-mobile {
      display: none;
      @media only screen and (max-width: 768px) {
        display: block;
      }
    }
  }
  nav {
    ul {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 52px;
      @media only screen and (max-width: 768px) {
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 12px;
      }
      li {
        margin-left: 43px;
        @media only screen and (max-width: 992px) {
          margin-left: 20px;
        }
        @media only screen and (max-width: 768px) {
          min-width: 45%;
          margin: 0 0 20px 0;
        }
        a {
          transition: .3s;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          text-transform: capitalize;
          color: $dark;
          &:hover {
            transition: .3s;
            text-decoration: navajowhite;
            color: $green;
          }
        }
      }
    }
  }
  .address {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 48px;
    @media only screen and (max-width: 768px) {
      align-items: flex-start;
      margin-bottom: 32px;
    }
    p {
      margin: 0;
      display: block;
      width: 100%;
      max-width: 270px;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      color: $grey;
      a {
        color: $grey;
      }
    }
  }
  .socials {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    @media only screen and (max-width: 768px) {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }
    ul {
      display: flex;
      li {
        margin: 0 0 37px 26px;
        @media only screen and (max-width: 768px) {
          margin: 0 28px 0 0;
        }
        a {
          transition: .3s;
          &:hover {
            transition: .3s;
            svg {
              transform: scale(1.1);
            }
          }
          svg {
            transition: .3s;
            height: 40px;
          }
        }
      }
    }
  }
  .flex {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    @media only screen and (max-width: 768px) {
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .links {
      ul {
        display: flex;
        li {
          margin-right: 33px;
          @media only screen and (max-width: 768px) {
            margin-right: 15px;
          }
          a {
            font-family: 'Outfit', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            color: rgba(24, 26, 32, 0.4);
            @media only screen and (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
    }
    .copyright {
      p {
        margin: 0;
        font-family: 'Outfit', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: rgba(24, 26, 32, 0.4);
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
  .flex-bottom {
    align-items: baseline;
  }
}