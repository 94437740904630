// About story

.about-story {
  background-color: #F5F6F7;
  padding: 175px 0;
  @media only screen and (max-width: 768px) {
    padding: 75px 0;
  }
  .row {
    align-items: center;
  }
  div[class="col-lg-12"] {
    .text {
      flex-direction: column;
      text-align: center;
      align-items: center;
      margin-bottom: 100px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 40px;
        text-align: left;
        .secondary-title {
          width: 100%;
        }
      }
      p {
        max-width: 770px;
        margin: 0 auto;
        font-size: 18px;
      }
    }
  }
  .text {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    h3 {
      margin: 40px 0 20px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      color: $dark;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
    p {
      font-size: 18px;
      line-height: 150%;
    }
    h3, p {
      width: 100%;
      max-width: 485px;
    }
  }
  .image {
    img {
      border-radius: 24px;
    }
  }
}

// About leadership

.about-leadership {
  padding: 175px 0 65px 0;
  @media only screen and (max-width: 768px) {
    padding: 75px 0 35px 0;
  }
  .row {
    align-items: center;
    div[class*="col-lg-6"] {
      @media only screen and (max-width: 768px) {
        &:first-child {
          order: 2;
        }
        &:nth-of-type(2) {
          order: 1;
        }
      }
    }
  }
  .text {
    .secondary-title {
      margin-bottom: 88px;
      text-align: center;
      @media only screen and (max-width: 768px) {
        margin-bottom: 40px;
        text-align: left;
      }
    }
    p {
      width: 100%;
      max-width: 470px;
      font-size: 18px;
    }
  }
  .image {
    @media only screen and (max-width: 768px) {
      margin-bottom: 40px;
    }
    img {
      border-radius: 24px;
    }
  }
}

// About team

.about-team {
  padding: 65px 0;
  @media only screen and (max-width: 768px) {
    padding: 35px 0;
  }
  &.directors {
    padding-bottom: 100px;
    @media only screen and (max-width: 768px) {
      padding-bottom: 35px;
    }
  }
  .text {
    .secondary-title {
      margin-bottom: 48px;
      font-size: 36px;
      line-height: 120%;
      @media only screen and (max-width: 768px) {
        margin-bottom: 40px;
        font-size: 26px;
        line-height: 31px;
      }
    }
  }
  .wrapper {
    .item {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      @media only screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
      &:hover {
        transition: .3s;
        .info {
          bottom: -1px;
          &:before {
            opacity: 1;
            top: -14px;
            right: -14px;
            @media only screen and (max-width: 768px) {
              top: -6px;
              right: -6px;
            }
          }
          h4 {
            display: block;
          }
        }
      }
      .avatar {
        width: 100%;
      }
      .info {
        position: absolute;
        bottom: -15px;
        left: 0;
        width: calc(100% - 38px);
        background-color: #FFFFFF;
        border-radius: 0 24px 0 0;
        padding: 30px 5px 18px 0;
        @media only screen and (max-width: 768px) {
          width: calc(100% - 15px);
          padding: 20px 5px 18px 0;
        }
        z-index: 1;
        transition: .3s;
        &:before {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          width: calc(100% + 14px);
          height: calc(100% + 14px);
          border-top: 2px solid $green;
          border-right: 2px solid $green;
          border-radius: 0 24px 0 0;
          opacity: 0;
          transition: .3s;
          @media only screen and (max-width: 768px) {
            width: calc(100% + 6px);
            height: calc(100% + 6px);
          }
        }
        h3 {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 120%;
          color: $dark;
          @media only screen and (max-width: 768px) {
            align-items: initial;
            font-size: 16px;
            line-height: 19px;
          }
          svg {
            margin-right: 12px;
            @media only screen and (max-width: 768px) {
              margin: 6px 8px 0 0;
              min-width: 20px;
              width: 20px;
            }
          }
        }
        h4 {
          display: none;
          margin-bottom: 0;
          padding-left: 45px;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 20px;
          color: $grey;
          @media only screen and (max-width: 768px) {
            padding-left: 28px;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
}