// Header

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px 0;
  z-index: 2;
  transition: .3s;
  &.sticky,
  &.header-dark {
    transition: .3s;
    background-color: white;
    .logo {
      img {
        &:first-child {
          display: none;
        }
        &:nth-of-type(2) {
          display: block;
        }
      }
    }
    .flex {
      nav {
        ul {
          li {
            a {
              transition: .3s;
              color: $dark;
            }
          }
        }
      }
      .action {
        .btn-arrow {
          color: $green-dark;
        }
      }
    }
  }
  &.active {
    &.sticky,
    &.header-dark {
      background-color: transparent;
      .logo {
        img {
          &:first-child {
            display: block;
          }
          &:nth-of-type(2) {
            display: none;
          }
        }
      }
      .flex {
        nav {
          ul {
            li {
              a {
                color: white;
              }
            }
          }
          .action {
            display: block;
          }
        }
      }
    }
    div[class*="col-"] {
      position: initial;
    }
    .logo {
      position: relative;
      z-index: 2;
    }
    .hamburger {
      position: relative;
      z-index: 2;
      a {
        svg {
          &:first-child {
            display: none;
          }
          &:nth-of-type(2) {
            display: block;
          }
        }
      }
    }
    .flex {
      nav {
        display: block!important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: linear-gradient(180deg, rgba(2, 4, 10, 0.1) 0%, rgba(2, 4, 10, 0.8) 100%);
        backdrop-filter: blur(5px);
        padding: 105px 40px 30px 40px;
        @media only screen and (max-width: 768px) {
          padding: 105px 15px 30px 15px;
        }
        ul {
          flex-direction: column;
          align-items: center;
          li {
            margin: 0 0 40px 0!important;
            a {
              font-size: 24px;
              line-height: 30px;
            }
          }
        }
        .action {
          display: block;
          text-align: center;
          .btn-arrow {
            height: 56px;
            padding: 0 78px 0 27px;
            font-size: 24px;
            color: white;
            @media only screen and (max-width: 768px) {
              padding: 0 60px 0 25px;
              width: 100%;
              max-width: 320px;
            }
            &:hover {
              color: white;
            }
            svg {
              width: 56px;
              height: 56px;
            }
          }
        }
      }
    }

  }
  .row {
    align-items: center;
  }
  .logo {
    img {
      height: 50px;
      @media only screen and (max-width: 768px) {
        height: 40px;
      }
      &:nth-of-type(2) {
        display: none;
      }
    }
  }
  .flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    nav {
      @media only screen and (max-width: 992px) {
        display: none;
      }
      ul {
        display: flex;
        li {
          margin-right: 45px;
          @media only screen and (max-width: 1024px) {
            margin-right: 40px;
          }
          a {
            transition: .3s;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            text-transform: capitalize;
            color: #FFFFFF;
            &:hover {
              transition: .3s;
              text-decoration: none;
              color: $green;
            }
          }
        }
      }
      .action {
        display: none;
      }
    }
    .action {
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
    .hamburger {
      display: none;
      svg {
        &:nth-of-type(2) {
          display: none;
        }
      }
      @media only screen and (max-width: 992px) {
        display: block;
      }
    }
  }
}