// Home hero

.home-hero {
  position: relative;
  min-height: 790px;
  display: flex;
  align-items: center;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    min-height: 100%;
    padding: 100px 0 135px 0;
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 154px;
    background: linear-gradient(180deg, rgba(9, 23, 8, 0) 0%, rgba(9, 23, 8, 0.8) 100%);
    transform: rotate(-180deg);
  }
  .text {
    .main-title {
      max-width: 740px;
      margin-bottom: 28px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 16px;
      }
      span {
        display: inline-flex;
        background-image: url("../img/icon-robotics.svg");
        background-size: contain;
        background-repeat: no-repeat;
        height: 42px;
        width: 33px;
        @media only screen and (max-width: 768px) {
          height: 24px;
          width: 20px;
        }
      }
    }
    p {
      max-width: 570px;
      color: white;
      @media only screen and (max-width: 768px) {
        max-width: 290px;
      }
      &:last-of-type {
        margin-bottom: 56px;
        @media only screen and (max-width: 768px) {
          margin-bottom: 48px;
        }
      }
    }
    .btn-arrow {
      background-color: $green;
      color: $green-dark;
      &:hover {
        svg {
          right: -10px;
        }
      }
      svg {
        right: -3px;
      }
    }
  }
  .bg {
    img {
      &:first-of-type {
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: 768px) {
          display: block;
        }
      }
    }
  }
}

// Home slider

.home-slider-text {
  padding: 170px 0 0 0;
  background-color: #F5F6F7;
  @media only screen and (max-width: 768px) {
    padding: 70px 0 40px 0;
  }
  .text {
    text-align: center;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: 768px) {
      text-align: left;
    }
    p {
      max-width: 670px;
      margin: 0 auto 16px auto;
    }
    .vector {
      position: absolute;
      left: 50px;
      bottom: -50px;
      z-index: -1;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

.sub-section{
  width: 100%;
}

.mySwiper {
  padding: 50px 0!important;
  height: 100vh;
  @media only screen and (max-height: 750px) {
    padding: 15px 0!important;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    padding-left: 25%;
    @media only screen and (max-width: 1024px) {
      padding-left: 30%;
    }
    @media only screen and (min-width: 1600px) {
      padding-left: 23%;
    }
    @media only screen and (min-width: 1920px) {
      padding-left: 19%;
    }
  }
  .swiper-pagination {
    left: 0;
    right: initial;
    &:before {
      position: absolute;
      content: '';
      top: 7px;
      left: 18px;
      width: 1px;
      height: 79%;
      background-color: rgba(66, 68, 72, 0.2);
    }
    .swiper-pagination-bullet {
      margin: 0 0 50px 0!important;
      position: relative;
      outline: none;
      background-color: transparent;
      opacity: 1;
      &:before {
        position: absolute;
        content: '';
        transition: .3s;
        border-radius: 50px;
        min-width: 7px;
        width: 7px;
        height: 7px;
        z-index: 1;
        background-color: rgba(66,68,72,.2);
      }
      &.swiper-pagination-bullet-active {
        &:before {
          background-color: $green;
        }
        span {
          color: #181a20;
        }
      }
      span {
        display: inline-flex;
        width: 245px;
        @media only screen and (max-width: 1024px) {
          width: 210px;
        }
        padding-left: 20px;
        position: relative;
        top: -8px;
        transition: .3s;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: rgba(24, 26, 32, 0.5);
      }
    }
  }
}

.home-slider {
  position: relative;
  background-color: #F5F6F7;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  .row {
    align-items: center;
  }
  .tab-content {
    .tab-pane {
      &.fade {
        transition: all 0.5s;
        transform: translateY(1rem);
      }
      &.show {
        transform: translateY(0rem);
      }
    }
  }
  .panel {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    &:first-of-type {
      opacity: 1;
    }
    .item {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      height: 100%;
      padding: 50px 0;
      @media only screen and (max-width: 768px) {
        padding: 50px 0;
      }
      &.first {
        .bar {
          ul {
            &:after {
              @media only screen and (max-width: 768px) {
                height: 2px;
                width: 14%;
              }
            }
          }
        }
        .image {
          &:before {
            left: 165px;
            bottom: 22px;
          }
          &:after {
            display: none;
          }
        }
      }
      &.second {
        .bar {
          ul {
            &:after {
              height: 45%;
              @media only screen and (max-width: 768px) {
                height: 2px;
                width: 35%;
              }
            }
          }
        }
        .image {
          &:before {
            left: 40px;
            top: 122px;
          }
          &:after {
            right: 30px;
            top: 145px;
          }
        }
      }
      &.third {
        .bar {
          ul {
            &:after {
              height: 73%;
              @media only screen and (max-width: 768px) {
                height: 2px;
                width: 57%;
              }
            }
          }
        }
        .image {
          &:before {
            left: 70px;
            bottom: 140px;
          }
          &:after {
            display: none;
          }
        }
      }
      &.fourth {
        .bar {
          ul {
            &:after {
              height: 91%;
              @media only screen and (max-width: 768px) {
                height: 2px;
                width: 70%;
              }
            }
          }
        }
        .image {
          &:before {
            right: 95px;
            top: 165px;
          }
          &:after {
            display: none;
          }
        }
      }
      &.fifth {
        .bar {
          ul {
            &:after {
              height: 91%;
              @media only screen and (max-width: 768px) {
                height: 2px;
                width: 70%;
              }
            }
          }
        }
        .image {
          &:before {
            display: none;
          }
          &:after {
            display: none;
          }
        }
      }
      .flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          padding-left: 0;
        }
      }
      .text {
        min-height: 270px;
        @media only screen and (max-width: 768px) {
          min-width: 33%;
        }
        .title-main {
          min-height: 82px;
          max-width: 350px;
          margin-bottom: 23px;
          font-style: normal;
          font-weight: 600;
          font-size: 34px;
          line-height: 120%;
          color: $dark;
          @media only screen and (max-width: 992px) {
            min-height: auto;
            font-size: 34px;
            margin-bottom: 15px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 30px;
            line-height: 34px;
            margin-bottom: 10px;
          }
        }
        p {
          min-height: 86px;
          max-width: 340px;
          font-size: 18px;
          color: $grey;
          @media only screen and (max-width: 992px) {
            min-height: auto;
            font-size: 14px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
        .logos {
          position: relative;
          bottom: -130px;
          @media only screen and (max-height: 750px) {
            bottom: -40px;
          }
          img {
            max-height: 80px;
          }
        }
      }
      .image {
        max-width: 450px;
        padding-left: 15px;
        position: relative;
        text-align: left;
        z-index: 1;
        @media only screen and (max-height: 750px) {
          max-width: 300px;
        }
        @media only screen and (max-width: 768px) {
          text-align: center;
        }
        &:before, &:after {
          position: absolute;
          content: '';
          width: 145px;
          height: 145px;
          background-image: url("../img/vector-pulse.svg");
          background-repeat: no-repeat;
          background-size: cover;
          animation: pulse 2s linear infinite;
          z-index: 1;
        }
        img {
          width: 100%;
          @media only screen and (max-width: 768px) {
            max-height: 50vh;
          }
        }
      }
    }
  }
  .bar {
    .nav {
      border: 0;
      .nav-item {
        .nav-link {
          background-color: transparent;
          border: 0;
          padding: 0;
          &.active {
            .divider {
              background-color: $green;
            }
            h3 {
              color: $dark;
            }
          }
        }
      }
    }
    .line-colored {
      display: none;
      position: absolute;
      content: '';
      top: 7px;
      left: 3px;
      width: 1px;
      height: 14%;
      background-color: $green;
      @media only screen and (max-width: 992px) {
        left: 45px;
      }
      @media only screen and (max-width: 768px) {
        display: none;
        width: 70%;
        height: 2px;
        left: 15%;
        top: 28px;
      }
    }
    .line-colored-mobile {
      display: none;
      position: absolute;
      content: '';
      top: 28px;
      left: 15%;
      height: 2px;
      background-color: $purple;
      @media only screen and (max-width: 768px) {
        display: block;
      }
    }
    ul {
      position: relative;
      align-items: center;
      padding: 0;
      margin: 0;
      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      &:before {
        position: absolute;
        content: '';
        top: 7px;
        left: 3px;
        width: 1px;
        height: 88%;
        background-color: rgba(66, 68, 72, 0.2);
        //@media only screen and (max-width: 992px) {
        //  left: 45px;
        //}
        @media only screen and (max-width: 768px) {
          width: 70%;
          height: 2px;
          left: 15%;
          top: 28px;
        }
      }
      li {
        display: flex;
        margin-bottom: 42px;
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          align-items: center;
          margin: 0 20px;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        &.active {
          .divider {
            background-color: $green;
          }
          h3 {
            color: $dark
          }
        }
        .divider {
          transition: .3s;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          min-width: 7px;
          width: 7px;
          height: 7px;
          position: relative;
          z-index: 1;
          top: 7px;
          background-color: rgba(66, 68, 72, 0.2);
          @media only screen and (max-width: 768px) {
            top: initial;
          }
        }
        h3 {
          margin: -5px 0 0 20px;
          max-width: 220px;
          transition: .3s;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: rgba(24, 26, 32, 0.5);
          @media only screen and (max-width: 992px) {
            padding-left: 0;
            font-size: 14px;
          }
          @media only screen and (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
}

.home-slider-mobile {
  background-color: #F5F6F7;
  padding: 0 0 75px 0;
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
  .slider-panels {
    .swiper-slide {
      &:first-of-type {
        .flex {
          .image {
            &:before {
              left: 130px;
              bottom: 22px;
            }
            &:after {
              display: none;
            }
          }
        }
      }
      &:nth-of-type(2) {
        .image {
          &:before {
            left: 35px;
            top: 120px;
          }
          &:after {
            right: 35px;
            top: 140px;
          }
        }
      }
      &:nth-of-type(3) {
        .image {
          &:before {
            left: 55px;
            bottom: 130px;
          }
          &:after {
            display: none;
          }
        }
      }
      &:nth-of-type(4) {
        .image {
          &:before {
            right: 90px;
            top: 150px;
          }
          &:after {
            display: none;
          }
        }
      }
      &:nth-of-type(5) {
        .image {
          &:before {
            display: none;
          }
          &:after {
            display: none;
          }
        }
      }
      .flex {
        .text {
          margin-bottom: 28px;
          .title-main {
            min-height: 65px;
            margin-bottom: 20px;
            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 120%;
            color: $dark;
          }
          p {
            min-height: 81px;
            font-size: 18px;
          }
        }
        .image {
          position: relative;
          &:before, &:after {
            position: absolute;
            content: '';
            width: 100px;
            height: 100px;
            background-image: url("../img/vector-pulse.svg");
            background-repeat: no-repeat;
            background-size: cover;
            animation: pulse 2s linear infinite;
            z-index: 1;
          }
        }
      }
    }
    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: -55px 10px 32px 10px;
      opacity: 0.8;
      position: relative;
      z-index: 1;
      .swiper-button-prev,
      .swiper-button-next {
        position: initial;
        width: 44px;
        height: 44px;
        background-image: none;
        margin: 0;
        outline: none;
        &:before,
        &:after {
          display: none;
        }
      }
      .swiper-pagination {
        display: flex;
        justify-content: space-between;
        position: initial;
        width: calc(100% - 110px);
        max-width: 250px;
        margin: 0 auto;
        &:before {
          position: absolute;
          content: '';
          top: 3.5px;
          left: 7px;
          width: calc(100% - 15px);
          height: 1px;
          background: #d9d9da;
        }
        .swiper-pagination-bullet {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 7px;
          height: 7px;
          background: #d9d9da;
          position: relative;
          outline: none;
          opacity: 1;
          transition: .3s;
          &:before {
            position: absolute;
            content: '';
            width: 9px;
            height: 9px;
            border: 2px solid #F5F6F7;
            border-radius: 50%;
            z-index: 1;
            transition: .3s;
          }
          &:after {
            position: absolute;
            content: '';
            left: 6px;
            top: 3.5px;
            width: 53px;
            height: 1px;
            background: rgba(66, 68, 72, 0);
            z-index: 1;
            transition: .3s;
          }
          &:last-of-type {
            &:after {
              display: none;
            }
          }
          &.swiper-pagination-bullet-active,
          &.active {
            transition: .3s;
            background-color: $green;
            &:after {
              transition: .3s;
              background-color: $green;
            }
          }
        }
      }
    }
  }
  .logos {
    text-align: center;
    img {
      height: 54px;
    }
  }
}

// Home resources

.home-resources {
  padding: 170px 0 140px 0;
  @media only screen and (max-width: 768px) {
    padding: 70px 0 40px 0;
  }
  .text {
    text-align: center;
    margin-bottom: 48px;
    @media only screen and (max-width: 768px) {
      text-align: left;
      margin-bottom: 44px;
    }
  }
  .wrapper {
    .item {
      background-color: #F5F6F6;
      border-radius: 24px;
      margin-bottom: 30px;
      text-align: center;
      &:hover {
        transition: .3s;
        .icon {
          img {
            transform: scale(1.1);
          }
        }
      }
      .info {
        padding: 40px 10px 5px 10px;
        @media only screen and (max-width: 768px) {
          padding: 30px 10px;
        }
        h3 {
          padding: 0 23px;
          margin-bottom: 17px;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 120%;
          color: $dark;
          @media only screen and (max-width: 1140px) {
            padding: 0;
          }
          @media only screen and (max-width: 768px) {
            padding: 0 70px;
            font-size: 20px;
          }
        }
        .icon {
          margin-bottom: 11px;
          @media only screen and (max-width: 768px) {
            margin-bottom: 16px;
          }
          img {
            transition: .3s;
            height: 90px;
            @media only screen and (max-width: 768px) {
              height: 70px;
            }
          }
        }
        p {
          min-height: 112px;
          font-size: 18px;
          @media only screen and (max-width: 1140px) {
            min-height: 150px;
          }
          @media only screen and (max-width: 992px) {
            min-height: 112px;
          }
          @media only screen and (max-width: 992px) {
            min-height: auto;
          }
        }
      }
      .save {
        background-color: $green;
        border-radius: 0 0 24px 24px;
        padding: 17px 14px;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 120%;
        color: #FFFFFF;
        @media only screen and (max-width: 992px) {
          font-size: 18px;
        }
        .percent {
          font-weight: 600;
          font-size: 24px;
          @media only screen and (max-width: 992px) {
            font-size: 20px;
          }
        }
      }
    }
  }
}

// Home data

.home-data {
  padding: 120px 0;
  @media only screen and (max-width: 992px) {
    padding: 72px 0;
  }
  .bg {
    img {
      &:first-of-type {
        @media only screen and (max-width: 992px) {
          display: none;
        }
      }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: 992px) {
          display: block;
        }
      }
    }
  }
  .row {
    align-items: center;
    div[class*="col-"] {
      @media only screen and (max-width: 992px) {
        &:first-of-type {
          order: 2;
        }
        &:nth-of-type(2) {
          order: 1;
        }
      }
    }
  }
  .text {
    width: 100%;
    max-width: 500px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 48px;
    }
    .secondary-title {
      margin-bottom: 26px;
      font-weight: 600;
      font-size: 36px;
      color: #FFFFFF;
      @media only screen and (max-width: 992px) {
        font-size: 34px;
      }
    }
    p {
      width: 100%;
      max-width: 468px;
      color: white;
      b, strong {
        font-weight: 500;
        color: $green;
      }
    }
  }
  .image {
    img {
      width: 100%;
      max-width: 525px;
    }
  }
}

// Home trusted

.home-trusted {
  background-color: #F5F6F7;
  padding: 135px 0 110px 0;
  @media only screen and (max-width: 768px) {
    padding: 70px 0 40px 0;
  }
  .text {
    text-align: center;
    margin-bottom: 48px;
    @media only screen and (max-width: 768px) {
      text-align: left;
      margin-bottom: 44px;
    }
  }
  .wrapper {
    justify-content: center;
    flex-wrap: wrap;
    @media only screen and (max-width: 768px) {
      justify-content: space-between;
    }
    div[class*="col-custom"] {
      margin-bottom: 25px;
    }
    .item {
      background-color: #FFFFFF;
      border-radius: 13.3333px;
      margin: 0 15px;
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 768px) {
        width: 148px;
        height: 148px;
      }
      &:hover {
        transition: .2s;
        img {
          transform: scale(1.1);
        }
      }
      img {
        transition: .2s;
        max-width: 70%;
        max-height: 70%;
      }
    }
  }
}

// Home learn

.home-learn {
  padding: 90px 0;
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    padding: 70px 0;
  }
  .row {
    align-items: center;
  }
  .text {
    max-width: 390px;
    margin: 0 auto;
    @media only screen and (max-width: 992px) {
      max-width: 100%;
      margin-bottom: 48px;
    }
    .secondary-title {
      margin-bottom: 31px;
      font-size: 36px;
      color: white;
      @media only screen and (max-width: 768px) {
        margin-bottom: 28px;
      }
    }
    .btn-arrow {
      background-color: $green;
      color: $green-dark;
      &:hover {
        svg {
          right: -10px;
        }
      }
      svg {
        right: -3px;
      }
    }
  }
  .image {
    text-align: center;
    @media only screen and (max-width: 992px) {
      text-align: right;
    }
    img {
      max-width: 510px;
      @media only screen and (max-width: 992px) {
        max-width: 100%;
      }
    }
  }
}

// Home grant

.home-grant {
  padding: 85px 0;
  @media only screen and (max-width: 768px) {
    padding: 70px 0;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .logo {
      min-width: 85px;
      margin-right: 37px;
      @media only screen and (max-width: 768px) {
        height: 80px;
        margin: 0 0 30px 0;
      }
      img {
        height: 85px;
      }
    }
    p {
      margin-bottom: 0;
      max-width: 700px;
      font-size: 18px;
      color: $grey;
    }
  }
}