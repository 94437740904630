// Demo request

.demo-request {
  padding: 120px 0;
  position: relative;
  margin-top: 84px;
  @media only screen and (max-width: 992px) {
    padding: 0 0 75px 0;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 80px;
  }
  .bg {
    width: 47%;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  .text {
    position: relative;
    @media only screen and (max-width: 992px) {
      padding: 120px 0 60px 0;
      margin-bottom: 52px;
    }
    @media only screen and (max-width: 768px) {
      padding: 60px 0 40px 0;
    }
    .bg {
      display: none;
      width: calc(100% + 30px);
      left: -15px;
      @media only screen and (max-width: 992px) {
        display: block;
        img {
          height: 100%;
        }
      }
    }
    .main-title {
      margin-bottom: 26px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 24px;
      }
    }
    p {
      width: 100%;
      max-width: 430px;
      color: white;
      &:last-of-type {
        margin-bottom: 133px;
        @media only screen and (max-width: 768px) {
          margin-bottom: 40px;
        }
      }
    }
    .image {
      text-align: left;
      @media only screen and (max-width: 992px) {
        text-align: center;
      }
      img {
        width: 340px;
        @media only screen and (max-width: 768px) {
          width: 170px;
        }
      }
    }
  }
  .form {
    form {
      .nf-before-form-content {
        display: none;
      }
      .nf-form-content {
        padding: 0;
        nf-fields-wrap {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          nf-field {
            width: 48%;
            margin-bottom: 34px;
            @media only screen and (max-width: 768px) {
              width: 100%;
            }
            &:nth-last-child(4) {
              width: 100%;
              .nf-field-label {
                display: block;
              }
            }
            &:nth-last-child(3) {
              .nf-field-label {
                display: block;
                margin-bottom: 24px;
              }
              ul {
                display: flex;
                li {
                  margin: 0 40px 0 0!important;
                  label {
                    margin-left: 48px!important;
                    cursor: pointer;
                    &.nf-checked-label {
                      &:after {
                        border: 1px solid rgba(66, 68, 72, 0.2);
                      }
                    }
                    &:after {
                      left: -48px!important;
                      top: -5px;
                      bottom: initial;
                      width: 36px;
                      height: 36px;
                      border: 1px solid rgba(66, 68, 72, 0.2);
                      background-color: white;
                    }
                    &:before {
                      left: -39px;
                      top: 4px;
                      width: 18px;
                      height: 18px;
                      background-color: $green;
                    }
                  }
                }
              }
            }
            &:nth-last-child(2) {
              width: 55%;
              @media only screen and (max-width: 768px) {
                width: 100%;
              }
              .checkbox-container.label-right label {
                cursor: pointer;
                &:before {
                  left: -27px;
                  top: -3px;
                  font-size: 18px;
                  color: $green;
                  @media only screen and (max-width: 768px) {
                    top: -5px;
                  }
                }
                &:after {
                  top: 0;
                  width: 20px;
                  height: 20px;
                  border: 1px solid #D1D3D3;
                  border-radius: 4px;
                  background-color: transparent;
                  cursor: pointer;
                  @media only screen and (max-width: 768px) {
                    top: 0;
                  }
                }
              }
              .nf-field-label {
                display: block;
                width: auto;
              }
              .nf-field-element {
                input {
                  height: auto;
                }
              }
              .nf-field-description {
                margin-right: 0;
                p {
                  font-style: normal;
                  font-weight: 300;
                  font-size: 14px;
                  line-height: 18px;
                  color: rgba(24, 26, 32, 0.4);
                  a {
                    color: rgba(24, 26, 32, 0.4);
                    text-decoration: underline;
                  }
                }
              }
            }
            &:last-child {
              width: 38%;
              @media only screen and (max-width: 768px) {
                width: 100%;
              }
              .nf-field-element {
                position: relative;
                @media only screen and (max-width: 768px) {
                  max-width: 210px;
                }
                &:hover {
                  transition: .3s;
                  &:before {
                    right: -10px;
                  }
                }
                &:before {
                  position: absolute;
                  content: '';
                  right: 0;
                  height: 44px;
                  width: 44px;
                  background-image: url("../img/icon-submit.svg");
                  background-repeat: no-repeat;
                  background-size: cover;
                  transition: .3s;
                }
              }
              input[type="button"] {
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 44px;
                border: 1px solid $green;
                border-radius: 48px;
                padding: 0 44px 0 26px;
                outline: none;
                position: relative;
                transition: .3s;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                text-transform: capitalize;
                color: $green-dark;
                &:hover {
                  transition: .3s;
                  text-decoration: none;
                }
              }
            }
            .nf-field-label {
              display: none;
            }
            .ninja-forms-req-symbol {
              display: none;
            }
            label {
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 150%;
              color: rgba(66, 68, 72, 0.6);
            }
            input, select {
              height: 48px;
              background: none;
              appearance: none;
              border: 0;
              padding: 0;
              border-bottom: 1px solid rgba(66, 68, 72, 0.2);
              border-radius: 0;
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 150%;
              color: $grey;
              &::placeholder {
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 150%;
                color: rgba(66, 68, 72, 0.6);
              }
              &:focus {
                background-color: #F5F6F6;
                border-color: $green;
                padding: 0 16px;
              }
            }
            select {
              color: rgba(66, 68, 72, 0.6);
              &:focus {
                color: $grey;
              }
            }
            textarea {
              background-color: transparent;
              padding: 16px;
              min-height: 211px;
              border: 1px solid #D1D3D3;
              border-radius: 12px;
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 150%;
              color: $grey;
            }
            .nf-field-container {
              margin-bottom: 0;
            }
            .nf-error-msg {
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 120%;
              color: #C61C42;
            }
            .nf-error {
              input {
                border-color: #C61C42;
                &::placeholder {
                  color: #C61C42;
                }
              }
            }
            .nf-field-element {
              &:after {
                display: none;
              }
            }
          }
        }
      }
      .nf-after-form-content {
        .nf-error-msg {
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 120%;
          color: #C61C42;
        }
      }
    }
  }
  .thank-you {
    display: none;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 370px;
    h3 {
      margin-bottom: 23px;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 120%;
      color: $dark;
      @media only screen and (max-width: 768px) {
        margin-bottom: 16px;
        font-size: 26px;
      }
    }
    .image {
      margin: 60px 0 80px 0;
      @media only screen and (max-width: 768px) {
        margin: 40px 0 50px 0;
      }
      img {
        height: 175px;
        @media only screen and (max-width: 768px) {
          height: 120px;
        }
      }
    }
    p {
      color: $grey;
    }
    .learn {
      p {
        font-size: 18px;
      }
      a {
        color: $green;
      }
    }
  }
}