// Single post

.single-page {
  padding: 120px 0 0 0;
  @media only screen and (max-width: 768px) {
    padding: 100px 0 0 0;
  }
  .back {
    margin-bottom: 28px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
    a {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: $green;
      &:hover {
        transition: .3s;
        text-decoration: none;
        color: $green;
        svg {
          left: -5px;
        }
      }
      svg {
        margin-right: 8px;
        position: relative;
        left: 0;
        transition: .3s;
      }
    }
  }
  .text {
    width: 100%;
    max-width: 860px;
    margin: 0 auto;
    .main-title {
      margin-bottom: 60px;
      color: $dark;
      @media only screen and (max-width: 768px) {
        margin-bottom: 40px;
      }
    }
    .image {
      position: relative;
      margin-bottom: 32px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 24px;
      }
      img {
        width: 100%;
        height: auto;
        border-radius: 20px;
      }
      .caption {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #FFFFFF;
        border-radius: 0 24px 0 0;
        padding: 11px 20px 11px 0;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 120%;
        color: rgba(24, 26, 32, 0.5);
        @media only screen and (max-width: 768px) {
          width: 90%;
          font-size: 12px;
        }
      }
    }
    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 35px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 24px;
      }
      .left {
        .date {
          margin-bottom: 17px;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 120%;
          color: $grey;
          @media only screen and (max-width: 768px) {
            display: none;
          }
        }
        .item {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .category {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            padding: 0 12px;
            margin: 0 44px 0 0;
            background-color: $green;
            border-radius: 24px;
            position: relative;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            color: #fff;
            svg {
              width: 32px;
              position: absolute;
              right: -32px;
            }
          }
          .place {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 120%;
            color: $grey;
          }
        }
      }
      .right {
        @media only screen and (max-width: 768px) {
          display: none;
        }
        h3 {
          margin-bottom: 16px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: $grey;
        }
        .addtoany_shortcode {
          a {
            padding: 0 5px;
            &:first-of-type {
              padding-left: 0;
            }
          }
        }
      }
    }
    .line-border {
      display: flex;
      height: 1px;
    }
    .post-info {
      padding: 60px 0 35px 0;
      @media only screen and (max-width: 768px) {
        padding: 40px 0 28px 0;
      }
      p, ul li, ol li {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: $grey;
        b, strong {
          font-weight: 600;
        }
      }
      a {
        transition: .3s;
        color: $grey;
        &:hover {
          transition: .3s;
          text-decoration: none;
          color: $green;
        }
      }
    }
    .content {
      h1,h2,h3,h4,h5,h6 {
        margin-bottom: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        color: $dark;
      }
      p, ul li, ol li {
        margin-bottom: 20px;
        font-size: 18px;
      }
    }
    .share {
      display: none;
      @media only screen and (max-width: 768px) {
        display: block;
      }
      h3 {
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: $grey;
      }
      .addtoany_shortcode {
        a {
          padding: 0 5px;
          &:first-of-type {
            padding-left: 0;
          }
        }
      }
    }
  }
}

// Recommended

.recommended {
  padding: 60px 0 135px 0;
  @media only screen and (max-width: 768px) {
    padding: 35px 0 30px 0;
  }
  .text {
    max-width: 100%;
    .secondary-title {
      margin-bottom: 48px;
      font-size: 36px;
    }
  }
  .wrapper {
    .item {
      border-radius: 24px;
      background-color: #F5F6F6;
      @media only screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
      .image {
        display: inline-flex;
        height: 236px;
        width: 100%;
        @media only screen and (max-width: 768px) {
          height: 205px;
        }
        img {
          border-radius: 24px 24px 0 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          @media only screen and (max-width: 768px) {
            border-radius: 20px 20px 0 0;
          }
        }
      }
      .info {
        padding: 40px 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media only screen and (max-width: 768px) {
          padding: 28px 0 0 0;
        }
        .top {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 0 24px;
          margin-bottom: 24px;
          @media only screen and (max-width: 1200px) {
            padding: 0 20px;
          }
          @media only screen and (max-width: 768px) {
            margin-bottom: 20px;
          }
          .category {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            padding: 0 12px;
            margin: 0 44px 20px 0;
            background-color: $green;
            border-radius: 24px;
            position: relative;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            color: #FFFFFF;
            svg {
              width: 32px;
              position: absolute;
              right: -32px;
            }
          }
          .place {
            margin-bottom: 20px;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 120%;
            color: $grey;

          }
          .title {
            width: 100%;
            display: inline-flex;
            flex-wrap: wrap;
            h3 {
              min-height: 84px;
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 120%;
              color: $dark;
              @media only screen and (max-width: 768px) {
                min-height: auto;
                font-size: 20px;
              }
            }
          }
        }
        .bottom {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          padding: 0 0 0 24px;
          @media only screen and (max-width: 1200px) {
            padding: 0 0 0 20px;
          }
          .date {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 120%;
            color: $grey;
          }
          .more {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-radius: 24px 0 0 0;
            padding: 21px 24px 35px 24px;
            white-space: nowrap;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $green;
          }
        }
      }
    }
  }
}