// Global

.elementor-element {
  margin-bottom: 0!important;
}

html, body {
  overflow-x: hidden;
  position: relative;
}

body {
  font-family: 'Lexend', sans-serif;
  .container {
    max-width: 1170px;
  }
}

p {
  font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 160%;
  color: $grey;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
  b, strong {
    font-weight: 600;
  }
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }
}

.btn-arrow {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border: 1px solid $green;
  border-radius: 48px;
  padding: 0 63px 0 26px;
  outline: none;
  position: relative;
  transition: .3s;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #FFFFFF;
  &:hover {
    transition: .3s;
    text-decoration: none;
    color: #FFFFFF;
    svg {
      right: -10px;
    }
  }
  svg {
    transition: .3s;
    position: absolute;
    right: -2px;
  }
}

.main-title {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 115%;
  color: #FFFFFF;
  @media only screen and (max-width: 768px) {
    font-size: 34px;
    line-height: 42px;
  }
}

.secondary-title {
  margin-bottom: 26px;
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: $dark;
  @media only screen and (max-width: 768px) {
    margin-bottom: 24px;
    font-size: 34px;
    line-height: 42px;
  }
}

// Scrollbar

::-webkit-scrollbar {
  width: 12px;
  height: 250px;
}

::-webkit-scrollbar-thumb {
  background-color: $green;
  height: 250px;
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  background: 0 0;
}

// Modal

.modal-open {
  padding-right: 0!important;
}


.modal#modal-request, .modal-tech {
  padding-right: 0!important;
  overflow: hidden;
  .modal-dialog {
    max-width: 890px;
    @media only screen and (max-width: 992px) {
      max-width: 100%;
    }
    .modal-content {
      background-color: transparent;
      border-radius: 0;
      border: 0;
      position: relative;
      .close {
        position: absolute;
        right: 0;
        top: -45px;
        z-index: 1;
        color: white;
        opacity: 1;
        text-shadow: none;
        font-size: 40px;
        font-weight: 500;
        &:hover {
          color: white;
          opacity: 1;
        }
      }
      .modal-body {
        padding: 0;
        text-align: center;
        overflow:auto;
      }
    }
  }
}

// Pulse animation

@-webkit-keyframes pulse{
  0%{
    opacity: 0;
    transform: scale(0.8);
  }

  50%{
    opacity: 1;
    transform: scale(1);
  }

  100%{
    opacity: 0;
    transform: scale(1.2);
  }
}

// Enable

#enable-toolbar {
  .keyboard-shorcut {
    display: none!important;
  }
}

