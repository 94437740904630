// Limited offer

.limited-offer {
  background-color: #F5F6F6;
  padding: 20px 0 0 0;
  margin-top: 90px;
  @media only screen and (max-width: 768px) {
    padding-top: 40px;
  }
  .row {
    .text {
      width: 100%;
      max-width: 470px;
      margin-top: 105px;
      @media only screen and (max-width: 1030px) {
        margin-top: 0;
      }
      @media only screen and (max-width: 768px) {
        text-align: center;
        margin-bottom: 30px;
      }
      .main-title {
        color: $dark;
        margin-bottom: 28px;
        @media only screen and (max-width: 768px) {
          font-size: 44px;
          margin-bottom: 16px;
        }
        span {
          display: inline-flex;
          background-image: url('../img/icon-limited-robotics.svg');
          background-size: contain;
          background-repeat: no-repeat;
          width: 42px;
          height: 48px;
          @media only screen and (max-width: 768px) {
            width: 32px;
            height: 38px;
          }
        }
      }
    }
    .image {
      img {
        width: 100%;
      }
    }
  }
}

// Limited table

.limited-table {
  padding: 0 0 135px 0;
  margin-top: -128px;
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 1030px) {
    margin-top: -30px;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 60px;
    margin-top: -72px;
  }
  .table-wrapper {
    width: 100%;
    max-width: 970px;
    margin: 0 auto;
    border-radius: 24px;
    background: rgba(2, 4, 10, 0.75);
    backdrop-filter: blur(4px);
    padding: 40px 40px 60px 40px;
    @media only screen and (max-width: 768px) {
      padding: 30px 16px 40px 16px;
    }
    .text {
      margin-bottom: 40px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 22px;
      }
      p {
        width: 100%;
        max-width: 740px;
        margin: 0 auto;
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
        b, strong {
          color: $green;
          font-weight: 600;
        }
      }
    }
    .table {
      h3 {
        padding: 16px;
        margin: 0;
        width: 100%;
        border-radius: 16px 16px 0 0;
        background: $green;
        color: $dark;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        @media only screen and (max-width: 768px) {
          padding: 12px;
        }
      }
      .top {
        display: flex;
        justify-content: space-between;
        border-radius: 0 0 16px 16px;
        background-color: #575961;
        .item {
          padding: 20px 25px;
          color: $green;
          font-family: 'Outfit', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          @media only screen and (max-width: 992px) {
            padding: 16px 8px;
            font-size: 12px;
          }
          &:first-of-type {
            width: 40%;
            padding-left: 70px;
            @media only screen and (max-width: 992px) {
              padding-left: 8px;
            }
          }
          &:nth-of-type(2) {
            width: 16%;
            @media only screen and (max-width: 768px) {
              width: 30%;
            }
          }
          &:nth-of-type(3) {
            width: 30%;
            position: relative;
            &:before {
              position: absolute;
              content: '';
              left: 0;
              top: 8px;
              width: 100%;
              height: 128px;
              border-radius: 12px;
              background-image: url("../img/bg-offer.svg");
              background-repeat: no-repeat;
              background-size: contain;
              @media only screen and (max-width: 1030px) {
                height: 195%;
              }
              @media only screen and (max-width: 768px) {
                background-image: url("../img/bg-offer-mob.svg");
                width: calc(100% - 4px);
              }
            }
          }
          &:nth-of-type(4) {
            width: 17%;
            @media only screen and (max-width: 768px) {
              display: none;
            }
          }
        }
      }
      .row-wrapper {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #575961;
        .item {
          width: 23%;
          padding: 24px 25px;
          font-family: 'Outfit', sans-serif;
          color: #FFF;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: 150%;
          @media only screen and (max-width: 992px) {
            padding: 16px 8px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 14px;
          }
          &:first-of-type {
            width: 40%;
            padding: 24px 15px 24px 0;
            @media only screen and (max-width: 992px) {
              padding: 16px 8px;
            }
            span {
              width: 28px;
              height: 28px;
              margin-right: 38px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background-color: $green;
              border-radius: 50%;
              color: #FFF;
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%;
              position: relative;
              @media only screen and (max-width: 992px) {
                display: none;
              }
              &:before {
                position: absolute;
                content: '';
                width: 20px;
                height: 1px;
                background-color: $green;
                right: -20px;
              }
              &:after {
                position: absolute;
                content: '';
                width: 5px;
                height: 5px;
                background-color: $green;
                border-radius: 50%;
                right: -25px;
              }
            }
          }
          &:nth-of-type(2) {
            width: 16%;
            color: #949F9F;
            text-decoration: line-through;
            @media only screen and (max-width: 768px) {
              width: 30%;
            }
          }
          &:nth-of-type(3) {
            width: 30%;
            span {
              display: inline-block;
              font-weight: 700;
              border-bottom: 1px solid $green;
            }
          }
          &:nth-of-type(4) {
            width: 17%;
            @media only screen and (max-width: 768px) {
              display: none;
            }
          }
        }
      }
      .table-small-text {
        text-align: center;
        margin: 24px 0 48px 0;
        @media only screen and (max-width: 768px) {
          margin: 13px 0 30px 0;
        }
        p {
          color: #949F9F;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 120%;
          @media only screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
      .text {
        text-align: center;
        margin-bottom: 0;
        h4 {
          margin-bottom: 24px;
          color: #FFF;
          font-size: 36px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          @media only screen and (max-width: 768px) {
            margin-bottom: 20px;
            font-size: 34px;
          }
        }
        p {
          margin-bottom: 40px;
          font-weight: 300;
          @media only screen and (max-width: 768px) {
            font-size: 18px;
            margin-bottom: 30px;
          }
        }
        .btn-arrow {
          background-color: $green;
          color: $dark;
        }
      }
    }
  }
}

// Limited offer modal

#modal-offer {
  .modal-dialog {
    max-width: 942px;
    .modal-content {
      border: 1px solid $green;
      border-radius: 4px;
      backdrop-filter: blur(5px);
      position: relative;
      button {
        opacity: 1;
        z-index: 1;
        position: absolute;
        right: 5px;
        top: 5px;
      }
      .modal-body {
        padding: 0;
        .flex {
          display: flex;
          justify-content: space-between;
          @media only screen and (max-width: 768px) {
            flex-direction: column;
          }
          .image {
            width: 100%;
            max-width: 365px;
            @media only screen and (max-width: 768px) {
              height: 220px;
              max-width: 100%;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .form {
            width: calc(100% - 365px);
            padding: 60px;
            @media only screen and (max-width: 992px) {
              padding: 40px 20px 5px 20px;
            }
            @media only screen and (max-width: 768px) {
              width: 100%;
            }
            .text {
              margin-bottom: 44px;
              text-align: center;
              .main-title {
                color: $dark;
                font-weight: 500;
                margin-bottom: 24px;
                @media only screen and (max-width: 768px) {
                  margin-bottom: 20px;
                }
              }
              p {
                color: #424448;
                font-size: 16px;
                font-weight: 600;
                max-width: 410px;
                margin: 0 auto;
              }
            }
            .form-wrapper {
              form {
                width: 100%;
                max-width: 270px;
                margin: 0 auto;
                @media only screen and (max-width: 992px) {
                  max-width: 100%;
                }
                .nf-before-form-content {
                  display: none;
                }
                .nf-form-content {
                  padding: 0;
                  nf-fields-wrap {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    nf-field {
                      width: 100%;
                      margin-bottom: 34px;
                      &:last-child {
                        .nf-field-element {
                          position: relative;
                          &:hover {
                            transition: .3s;
                            &:before {
                              right: -10px;
                            }
                          }
                          &:before {
                            position: absolute;
                            content: '';
                            right: 0;
                            height: 44px;
                            width: 44px;
                            background-image: url("../img/icon-submit-white.svg");
                            background-repeat: no-repeat;
                            background-size: cover;
                            transition: .3s;
                            z-index: 1;
                          }
                        }
                        input[type="button"] {
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                          width: 100%;
                          height: 44px;
                          border: 1px solid $green;
                          background-color: $green;
                          border-radius: 48px;
                          padding: 0 44px 0 26px;
                          outline: none;
                          position: relative;
                          transition: .3s;
                          font-style: normal;
                          font-weight: 600;
                          font-size: 14px;
                          line-height: 18px;
                          text-transform: capitalize;
                          cursor: pointer;
                          color: $dark;
                          &:hover {
                            transition: .3s;
                            text-decoration: none;
                          }
                        }
                      }
                      .nf-field-label {
                        display: none;
                      }
                      .ninja-forms-req-symbol {
                        display: none;
                      }
                      label {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 150%;
                        color: rgba(66, 68, 72, 0.6);
                      }
                      input, select {
                        height: 48px;
                        background-color: transparent;
                        border: 0;
                        padding: 0;
                        border-bottom: 1px solid rgba(66, 68, 72, 0.2);
                        outline: none;
                        box-shadow: none;
                        appearance: none;
                        border-radius: 0;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 150%;
                        color: $grey;
                        &::placeholder {
                          font-style: normal;
                          font-weight: 300;
                          font-size: 18px;
                          line-height: 150%;
                          color: rgba(66, 68, 72, 0.6);
                        }
                        &:focus {
                          background-color: #F5F6F6;
                          border-color: $green;
                          padding: 0 16px;
                        }
                      }
                      textarea {
                        background-color: transparent;
                        padding: 16px;
                        min-height: 211px;
                        border: 1px solid #D1D3D3;
                        border-radius: 12px;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 150%;
                        color: $grey;
                      }
                      .nf-field-container {
                        margin-bottom: 0;
                      }
                      .nf-error-msg {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 120%;
                        color: #C61C42;
                      }
                      .nf-error {
                        input {
                          border-color: #C61C42;
                          &::placeholder {
                            color: #C61C42;
                          }
                        }
                      }
                      .nf-field-element {
                        &:after {
                          display: none;
                        }
                      }
                    }
                  }
                }
                .nf-after-form-content {
                  .nf-error-msg {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 120%;
                    color: #C61C42;
                  }
                }
              }
            }
            .thank-you {
              display: none;
              text-align: center;
              margin: 0 auto;
              width: 100%;
              max-width: 370px;
              h3 {
                margin-bottom: 23px;
                font-style: normal;
                font-weight: 600;
                font-size: 36px;
                line-height: 120%;
                color: $dark;
                @media only screen and (max-width: 768px) {
                  margin-bottom: 16px;
                  font-size: 26px;
                }
              }
              .image {
                margin: 60px 0 80px 0;
                @media only screen and (max-width: 768px) {
                  margin: 40px 0 50px 0;
                }
                img {
                  height: 175px;
                  @media only screen and (max-width: 768px) {
                    height: 120px;
                  }
                }
              }
              p {
                color: $grey;
              }
              .learn {
                p {
                  font-size: 18px;
                }
                a {
                  color: $green;
                }
              }
            }
          }
        }
      }
    }
  }
}