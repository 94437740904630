// Default

.default {
  margin-top: 84px;
  padding: 80px 0;
  position: relative;
  @media only screen and (max-width: 768px) {
    margin-top: 80px;
    padding: 40px 0;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-top: 30px;
    color: $dark;
  }
}